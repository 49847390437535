import React, { useEffect, useState } from "react";
import "./section.css";
import tours from "../datebase/tours";
import imgdate from "../datebase/imgdata";
import { Link } from "react-router-dom";
const Section = ({ t, setOpenReq }) => {
  document.title = "Aral Sea Discovery";
  const [openImg, setopenImg] = useState(false);
  const [srcImg, setsrcImg] = useState("");
  const opImg = (e) => {
    setopenImg(true);
    setsrcImg(e.src);
  };
  const closeImg = () => {
    setopenImg(false);
  };
  useEffect(() => {
    const openFon = document.querySelector(".img-open");
    const imgSrc = document.querySelector(".openImg");
    if (openImg === true) {
      openFon.classList.add("active");
      imgSrc.src = srcImg;
    } else {
      setopenImg(false);
      openFon.classList.remove("active");
    }
  });
  useEffect((e) => {
    document.onclick = (e) => {
      if (e.target.className === "img-open active") {
        e.stopPropagation();
        setopenImg(false);
      }
    };
  });
  return (
    <>
      <section className="section1">
        <div className="container"></div>
      </section>
      <section className="section2">
        <div className="container">
          <div className="sec2-box">
            <h3>{t("sec2-text1")}</h3>
            <h2>{t("sec2-text2")}</h2>
            <h4>{t("sec2-text3")}</h4>
            <div className="sec2-btn">
              <a href="#tour" className="sec2-button link">
                {t("sec2-text4")}
              </a>
              <button
                onClick={() => {
                  setOpenReq(true);
                }}
                className="sec2-button"
              >
                {t("btn-zayav")}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="section3" id="tour">
        <div className="container">
          <div className="sec-3-flex">
            <div className="sec-text-align">
              <div className="sec-3-text">
                <h5>{t("sec2-text4")}</h5>
              </div>
            </div>
            <div className="sec-3-maps">
              <Link onClick={() => { window.scrollTo(0, 0); }} className="link" to={"/tours/1"}>
                <div className="sec-3-box" >
                  <div className="sec-3-box-img">
                    <img src="https://lh3.googleusercontent.com/fife/ALs6j_Gbm8HtdNMaK9Dk4_LxpcCMv1MvKGoY6RdZ-x6FFWS_R8-wQl8TlU-PRNti3VioCjOAVjTFwJWRCRRekKm_tqDJfg-5u0kFSjmpqTa3_dObKaHOuL_SSS56SYpNf3w6ItYkPOc3HDW0U7g0ztvetaHLxOpDaZ8r8KPKRDJboJ16I4k7pIM0AQFI7TcpJsVVOFrtVZolfvFR4Di5_irmKK0vWXkM5njaSE0YopE20ojkKSkMGkF95v6oomRmmTCA0qJ32zYw5OgXoWrLeIBpPKLoKlBWvMBvBfwBI04ca-BfZ2T440t0D_rdUreVA64i8zVr4IzTjvHwyAc0WkJR-yVLo5xjBdzIrxXjGRgp1iSkR4MhzSyeTqZBUfH2Of6uLZWKPRc0VbaD4S8oUzRH_5qNuwei2Djtskm9UhuKt8k79mwaS5M1YhZA3JJPPh1fGrhYPrhUnIYCBK4Y8rKVKJ7zaGwpE37ebenypmB1qc55RabJcFMC8fO4u-lSX8KA-LJFkbVrXi5ikkAnNkANr1dow6mhjUSkZJ-jVgGM3yri_euL8VF0vyfEs7goa4QTQ0F1-7WK4HGzaCmg3s9wYHYIcRHC_VSJQzcRhr6BIHeBPPqzpHIc8W6oTWw7VvkkiDAqCUrlWDOVIs0BF1eAbigUtdfArq_RunxIl-9ADhwojmxL0b_00RQuYifFrqcQzbnjwILZrHEdPCiASTGihSOV0ISbYtflkYXFhclHdfDRsKaotMqikN5YGTLNfzpk9vEQXv4c4ryRzCSYB8bfAZsSmefaTLnz67ny_VrU_quP2hqyVgPa7KZqcVbwAB1CP9BwxRbXVlom6Z7Wy0bNAlXZDWO7QY9JBoUR5-dhbGXwpmQsTx4fKOAN_5OFCU0EaXHrW-zB6s5tIv611m0wWmeJZsASTUP3SCxi6okZAnKetq6LDJqpgrgX4aUDrPJn-OtkgBwl-rBJFgUoLKanjSAZ6lGSMoPLvc40d8iSdkZQL-hgJGl_0KaQt4sFcAtIJF55H4K9rD_jKHXzQu0MS63fd37FRJ_ODjzF3MQevPrFj8ax3MavMo19jZVUdZ10P6wCNUroOeRbgJtgdhWMk9jEPKqJMpKEBwxytQh3MTuAy7xvPBC2oakDnAx7qNi8RqjOKPrYQZB-2btdeIXd5wprji-QFHuwlzynXqv3NsdPtq4IrM2wGg5V4weLewrL4M31eN5Gc_YxDevKMvNuAUZo0Z1Iksa3iqSen7fHyfZufC68YraI57Z3MQRmD9dMNUgt1fW2iCmEVvGWhiECC7YgjYAd6mShBOdMJp4gMWeClZ0lWuH3GWUOBl-rkLPIAoBTtcQZH3B1ucXqV6CJJudKk3CAbP99C5wu1s8mvuXLsFPJLdebxJDXuphv2H6I-K3ljMzHqSd5zNW4IZt8OKiUm_gTHftBcpptz-ZVW2AghTbRO7CBS1CujPO-dPecU1xPqgEXfJbSokc5X5qgX3Vdp0t5ib4JGh2iR-MB-VdOyDEY0S_jcxYWIOflIoDeIEIBC_2s0hyEl3Zzy33nFOQXQz-Ww3CXq1wns6n04bWjysZWoiTEKe62-TsRuXeEW4HFzvK8hIOxWeM69TagbTa9sLgQhrl-amD61J7Wh0Osd5TkS2LBhvYDRtBWgAFCzawViNbCo_fsiDQNMHanrDckjJzgjapveLNw_abhbSrEpKlW3fGiujtdnFr_=w1428-h944" alt="" />
                  </div>
                  <div className="sec-3-box-text">
                    <h5>{t("tour1.toursidname")}</h5>
                    <h3>
                      <i class="fa fa-usd" aria-hidden="true"></i>
                      {t("tour1.toursPirse")}
                    </h3>
                  </div>
                </div>
              </Link>
              {tours.map((koks) => {
                if (koks.id > 2) {
                  return (
                    <Link
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                      className="link"
                      to={"/tours/" + koks.id}
                    >
                      <div className="sec-3-box" key={koks.id}>
                        <div className="sec-3-box-img">
                          <img src={koks.img[0]} alt="" />
                        </div>
                        <div className="sec-3-box-text">
                          <h5>{t("tour" + koks.id + ".toursidname")}</h5>
                          <h3>
                            <span>{t("pricefrom")}</span>
                            <i class="fa fa-usd" aria-hidden="true"></i>
                            {koks.price}
                          </h3>
                        </div>
                      </div>
                    </Link>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </section>
      <span className="line-sections"></span>
      <section className="section4">
        <div className="container">
          <div className="sec-4-flex">
            <div className="sec-text-align">
              <div className="sec-3-text">
                <h5>{t("gallery")}</h5>
              </div>
            </div>
            <div className="img-open">
              <span className="span-open-img">
                <img className="openImg" src="" alt="" />
                <button className="btn-close-img" onClick={closeImg}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </span>
            </div>
            <div className="sec-4-grid">
              {imgdate.map((koks) => {
                if (koks.id <= 4) {
                  return (
                    <div key={koks.id}>
                      <img
                        src={koks.src}
                        onClick={() => {
                          opImg(koks);
                        }}
                        key={koks.id}
                        alt=""
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="section5">
        <section className="section5-text">
          <div className="container">
            <div className="sec-5-flex">
              <div className="sec5-box">
                <h5>{t("sec2-text1")}</h5>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};
export default Section;
