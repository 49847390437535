import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import "./feedback.css"
import { Link, useParams } from "react-router-dom";
const Feedback = ({ t }) => {
    const { id } = useParams();
    const [starscore, setStarScore] = useState(0)
    const [statusFind1, setStatusFind1] = useState(0)
    const [statusFind2, setStatusFind2] = useState(0)
    const [statusFind3, setStatusFind3] = useState(0)
    const [statusFind, setStatusFind] = useState(statusFind1 + statusFind2 + statusFind3)
    useEffect(() => {
        const feedconfirm = document.querySelector(".feedconfirm")
        feedconfirm.addEventListener("click", () => {
            const feedzagolovok = document.querySelector("#feedzagolovok");
            const feedmessage = document.querySelector("#feedmessage");
            const feedzagolovoklab = document.querySelector("#feedzagolovoklab");
            const feedmessagelab = document.querySelector("#feedmessagelab");
            const feedbackstarlab = document.querySelector("#feedbackstarlab");
            const feedbtntext = document.querySelector(".feedbtntext");
            if (feedzagolovok.value == "") {
                feedzagolovok.classList.add("active")
                feedzagolovoklab.classList.add("active")
                if (feedmessage.value == "") {
                    feedmessage.classList.add("active")
                    feedmessagelab.classList.add("active")
                    if (starscore == 0) {
                        feedbackstarlab.classList.add("active")
                        feedbtntext.classList.remove("activeG")
                        feedbtntext.classList.add("activeX")
                        feedbtntext.innerHTML = t("fillfeedform")
                    } else {
                        feedbackstarlab.classList.remove("active")
                        feedbtntext.classList.remove("activeG")
                        feedbtntext.classList.add("activeX")
                        feedbtntext.innerHTML = t("fillfeedform")
                    }
                } else {
                    feedmessage.classList.remove("active")
                    feedmessagelab.classList.remove("active")
                    if (starscore == 0) {
                        feedbackstarlab.classList.add("active")
                        feedbtntext.classList.remove("activeG")
                        feedbtntext.classList.add("activeX")
                        feedbtntext.innerHTML = t("fillfeedform")
                    } else {
                        feedbackstarlab.classList.remove("active")
                        feedbtntext.classList.remove("activeG")
                        feedbtntext.classList.add("activeX")
                        feedbtntext.innerHTML = t("fillfeedform")
                    }
                }
            } else {
                feedzagolovok.classList.remove("active")
                feedzagolovoklab.classList.remove("active")
                if (feedmessage.value == "") {
                    feedmessage.classList.add("active")
                    feedmessagelab.classList.add("active")
                    if (starscore == 0) {
                        feedbackstarlab.classList.add("active")
                        feedbtntext.classList.remove("activeG")
                        feedbtntext.classList.add("activeX")
                        feedbtntext.innerHTML = t("fillfeedform")
                    } else {
                        feedbackstarlab.classList.remove("active")
                        feedbtntext.classList.remove("activeG")
                        feedbtntext.classList.add("activeX")
                        feedbtntext.innerHTML = t("fillfeedform")
                    }
                } else {
                    feedmessage.classList.remove("active")
                    feedmessagelab.classList.remove("active")
                    if (starscore == 0) {
                        feedbackstarlab.classList.add("active")
                        feedbtntext.classList.remove("activeG")
                        feedbtntext.classList.add("activeX")
                        feedbtntext.innerHTML = t("fillfeedformstar")
                    } else {
                        feedbackstarlab.classList.remove("active")
                        feedbtntext.classList.remove("activeX")
                        feedbtntext.classList.add("activeG")
                        feedbtntext.innerHTML = t("fillfeedformSucc")
                        form1func()
                    }
                }
            }
        })
        const form1func = (e) => {
            const TOKEN = "7095992067:AAHUHBaAq05KUxhsCDeUa3Gyz-MgCQO2Upc";
            const CHAT_ID = "-1001545016749";
            const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;
            const feedzagolovok = document.querySelector("#feedzagolovok");
            const feedbtntext = document.querySelector(".feedbtntext");
            const feedmessage = document.querySelector("#feedmessage");
            let mes = `<b>Новый отзыв с сайта</b>\n`;
            mes += `<b>ID тура:</b>${id}\n`;
            mes += `<b>ФИО: </b>${feedzagolovok.value}\n`;
            mes += `<b>Отзыв: </b>${feedmessage.value}\n`;
            mes += `<b>Оценка: </b>${starscore}\n`;
            axios.post(URI_API, {
                chat_id: CHAT_ID,
                parse_mode: "HTML",
                text: mes
            })
                .then((res) => {

                })
                .catch((error) => {
                })
                .finally(() => {
                    feedmessage.value = ""
                    feedzagolovok.value = ""
                })
        }
    })
    useEffect(() => {
        const backPagestar1 = document.querySelector("#backPage-star1");
        const backPagestar2 = document.querySelector("#backPage-star2");
        const backPagestar3 = document.querySelector("#backPage-star3");
        const backPagestar4 = document.querySelector("#backPage-star4");
        const backPagestar5 = document.querySelector("#backPage-star5");
        if (starscore == 1) {
            backPagestar1.classList.add("active")
            backPagestar2.classList.remove("active")
            backPagestar3.classList.remove("active")
            backPagestar4.classList.remove("active")
            backPagestar5.classList.remove("active")
        }
        else if (starscore == 2) {
            backPagestar1.classList.add("active")
            backPagestar2.classList.add("active")
            backPagestar3.classList.remove("active")
            backPagestar4.classList.remove("active")
            backPagestar5.classList.remove("active")
        }
        else if (starscore == 3) {
            backPagestar1.classList.add("active")
            backPagestar2.classList.add("active")
            backPagestar3.classList.add("active")
            backPagestar4.classList.remove("active")
            backPagestar5.classList.remove("active")
        }
        else if (starscore == 4) {
            backPagestar1.classList.add("active")
            backPagestar2.classList.add("active")
            backPagestar3.classList.add("active")
            backPagestar4.classList.add("active")
            backPagestar5.classList.remove("active")
        }
        else if (starscore == 5) {
            backPagestar1.classList.add("active")
            backPagestar2.classList.add("active")
            backPagestar3.classList.add("active")
            backPagestar4.classList.add("active")
            backPagestar5.classList.add("active")
        }
        else {
            backPagestar1.classList.remove("active")
            backPagestar2.classList.remove("active")
            backPagestar3.classList.remove("active")
            backPagestar4.classList.remove("active")
            backPagestar5.classList.remove("active")
        }
    })
    return (
        <>
            <div className="feedbackPage">
                <div className="container">
                    <div className="feedbackPage-flex">
                        <div className="feedbackPage-flex1">
                            <h5>{t("reviewsWrite")}</h5>
                            <h3>{t("reviewsWrite1")}</h3>
                        </div>
                        <div className="feedbackPage-flex2 feedbackPage-input">
                            <label for="feedzagolovok" id="feedzagolovoklab">
                                <span>{t("request.fio")}</span>|
                                <span>{t("strana")}</span>|
                                <span>{t("feedcontact")}</span>
                            </label>
                            <input type="text" id="feedzagolovok" />
                        </div>
                        <div className="feedbackPage-flex3 feedbackPage-input">
                            <label for="feedmessage" id="feedmessagelab">{t("feedQuest")}</label>
                            <textarea id="feedmessage" cols="30" rows="10"></textarea>
                        </div>
                        <div className="feedbackPage-flex4">
                            <label for="" id="feedbackstarlab">{t("scoreTours")}</label>
                            <div class="feedbackPage-star">
                                <span><i onClick={() => { setStarScore(1) }} class="fa fa-star backPage-star" id="backPage-star1" aria-hidden="true"></i></span>
                                <span><i onClick={() => { setStarScore(2) }} class="fa fa-star backPage-star" id="backPage-star2" aria-hidden="true"></i></span>
                                <span><i onClick={() => { setStarScore(3) }} class="fa fa-star backPage-star" id="backPage-star3" aria-hidden="true"></i></span>
                                <span><i onClick={() => { setStarScore(4) }} class="fa fa-star backPage-star" id="backPage-star4" aria-hidden="true"></i></span>
                                <span><i onClick={() => { setStarScore(5) }} class="fa fa-star backPage-star" id="backPage-star5" aria-hidden="true"></i></span>
                            </div>
                        </div>
                        <div className="feedbackPage-btn">
                            <button className="feedcancel feedbtns" onClick={() => { window.history.back() }}>{t("feedcancel")}</button>
                            <h3 className="feedbtntext"></h3>
                            <button className="feedconfirm feedbtns">{t("feedconfirm")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Feedback